import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { svgCheckmark, svgUnverified, svgGreenCheckmark } from '@/assets/icons';
import {
  BidirectionalVoting,
  InteractableComponent,
  Link,
  Modal,
  ReportedSection,
  Text,
} from '@/components';
import { FeatherIcon, FeatherIconName, Icon } from '@/components/icon';
import { LexicalViewer } from '@/components/lexical-viewer';
import { getRouteForProfile, PARTNER_RANSOMWHERE_LINK } from '@/types/routes';
import {
  getDisplayStringForScamCategory,
  ScamCategory,
} from '@/types/scam-categories';
import { ScamReport } from '@/types/scam-report';
import {
  getDisplayStringForReportSource,
  ReportSource,
} from '@/types/scam-report-source';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { getHumanReadbleDateString } from '@/utils/ago-on-string';
import { Tablet, UpToTablet } from '@/utils/responsive';
import { useMe } from '@/hooks';
import { BadgeInfoModalContent } from './components/badge-info-modal-content';
import { PrivateReportTooltip } from './components/private-report-tooltip';

export type ScamReportCardProps = StyleProps &
  ScamReport & {
    /**
     * Whether to display the card as a stacked variant
     */
    isStacked?: boolean;
    /**
     * Whether the card interactions are disabled
     */
    isDisabled?: boolean;
    /**
     * Callback function to execute when the card is being clicked
     */
    onClick?: () => void;
  };

const ROOT = makeRootClassName('ScamReportCard');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function RenderSubmittedByInfo(p: ScamReportCardProps): ReactElement {
  switch (p.source) {
    case ReportSource.REST_API:
    case ReportSource.CHAINABUSE:
    case ReportSource.PARTNER_LEDGER:
    case ReportSource.PARTNER_SAFEPAL:
    case ReportSource.PARTNER_1INCH:
    case ReportSource.PARTNER_1INCH_ANDROID:
    case ReportSource.PARTNER_1INCH_IOS:
    case ReportSource.PARTNER_BLOKK:
    case ReportSource.PARTNER_ACTION_FRAUD:
    case ReportSource.PARTNER_DANY:
    case ReportSource.PARTNER_FBI:
    case ReportSource.PARTNER_SANJOSECA_GOV:
    case ReportSource.PARTNER_OPP:
    case ReportSource.PARTNER_MTV:
    case ReportSource.PARTNER_COINBASE:
    case ReportSource.PARTNER_RCMP:
    case ReportSource.PARTNER_NYPD:
    case ReportSource.PARTNER_BITFLY:
    case ReportSource.PARTNER_SOLANAFM:
    case ReportSource.PARTNER_METAMASK:
    case ReportSource.PARTNER_AVASCAN:
    case ReportSource.PARTNER_WEBACY:
      return (
        <>
          <Text as="span" type="h5">
            Submitted by{' '}
          </Text>
          <Link
            size="xs"
            className={el`author-link`}
            href={getRouteForProfile(p.authorUsername)}
          >
            {p.authorUsername}
          </Link>
        </>
      );

    case ReportSource.BITCOINABUSE:
      return (
        <>
          <Text as="span" type="h5">
            Submitted in {getDisplayStringForReportSource(p.source)}
          </Text>
        </>
      );

    case ReportSource.PARTNER_RANSOMWHERE:
      return (
        <>
          <Text as="span" type="h5">
            Submitted by{' '}
          </Text>
          <Link
            size="xs"
            className={el`author-link`}
            href={PARTNER_RANSOMWHERE_LINK}
            isExternal
          >
            Ransomwhe.re
          </Link>
        </>
      );

    default:
      throw new Error(`Unknown ReportSource variant: ${p.source}`);
  }
}

function ScamReportCard(props: ScamReportCardProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const { me } = useMe();

  const { hoverProps, isHovered } = useHover({ isDisabled: p.isDisabled });
  const { pressProps, isPressed } = usePress({});

  const categoryTextType = p.isStacked ? 'body-md' : 'body-lg';
  const badgeModalContent = p.trusted
    ? { tooltipContent: 'Trusted Contributor', icon: svgCheckmark }
    : p.checked
    ? { tooltipContent: 'Checked Report', icon: svgGreenCheckmark }
    : { tooltipContent: 'Not Verified Report', icon: svgUnverified };

  const isMyReport = !!me && me.username === p.authorUsername;

  // only allow author to view private reports
  const behaviorProps =
    !isMyReport && p.isPrivate
      ? {}
      : mergeProps(pressProps, hoverProps, buttonize(p.onClick));

  return (
    <div
      className={clsx(ROOT, p.className, {
        'is-stacked': p.isStacked,
        'is-disabled': p.isDisabled,
        'is-hovered': isHovered,
        'is-pressed': isPressed,
      })}
    >
      <Modal.Root>
        <BadgeInfoModalContent />
        <div {...behaviorProps} className={el`main`}>
          {p.isPrivate && <PrivateReportTooltip isAuthor={isMyReport} />}
          <div className={el`content`}>
            <div className={el`category-section`}>
              <Text
                type={categoryTextType}
                isHeavy
                className={el`category-label`}
              >
                {getDisplayStringForScamCategory(p.category)}
                {p.category === ScamCategory.OTHER && p.categoryDescription && (
                  <>
                    :{' '}
                    <Text
                      as="span"
                      type={categoryTextType}
                      className={el`other-category-label`}
                    >
                      {p.categoryDescription && p.categoryDescription}
                    </Text>
                  </>
                )}
                <Modal.Trigger hasReactAriaChildren>
                  <InteractableComponent className={el`icon-wrapper`}>
                    <Icon
                      content={badgeModalContent.icon}
                      className={el`icon`}
                      tooltipContent={badgeModalContent.tooltipContent}
                    />
                  </InteractableComponent>
                </Modal.Trigger>
              </Text>

              {p.isStacked && !p.isPrivate ? (
                <BidirectionalVoting
                  size="small"
                  direction="horizontal"
                  vote={p.vote}
                  netVoteCount={p.netVoteCount}
                  reportId={p.reportId}
                />
              ) : null}
              {!p.isStacked && !p.isPrivate ? (
                <UpToTablet className={el`vote-section`}>
                  <BidirectionalVoting
                    size="small"
                    direction="horizontal"
                    vote={p.vote}
                    netVoteCount={p.netVoteCount}
                    reportId={p.reportId}
                  />
                </UpToTablet>
              ) : null}
            </div>
            <div
              className={clsx(el`body`, {
                'is-private': p.isPrivate,
              })}
            >
              <div className={el`preview-description-wrapper`}>
                <p className={el`preview-description`}>
                  <LexicalViewer
                    serializedEditorState={p.lexicalSerializedDescription}
                    fallbackText={p.description}
                  />
                </p>
              </div>
              <div className={el`submit-comments-info`}>
                <div className={el`submitted-info`}>
                  <RenderSubmittedByInfo {...p} />
                  <Text as="span" type="h5">
                    {getHumanReadbleDateString(p.reportDate)}
                  </Text>
                </div>
                <div className={el`num-comments`}>
                  <FeatherIcon
                    content={FeatherIconName.MESSAGE_CIRCLE}
                    size="small"
                    className={el`comment-icon`}
                    tooltipContent="Comments"
                  />
                  <Text as="span" type="h5" className={el`number-label`}>
                    {p.numComments}
                  </Text>
                </div>
              </div>
            </div>
            {!p.isStacked && !p.isPrivate ? (
              <Tablet className={el`vote-section`}>
                <BidirectionalVoting
                  vote={p.vote}
                  netVoteCount={p.netVoteCount}
                  reportId={p.reportId}
                />
              </Tablet>
            ) : null}
          </div>
          <ReportedSection
            addresses={p.addresses}
            variant={p.isStacked ? 'compact' : 'default'}
            className={clsx({
              'is-hovered': isHovered,
              'is-private': p.isPrivate,
            })}
          />
        </div>
      </Modal.Root>
    </div>
  );
}

export default ScamReportCard;
